import React, { createContext, useState,useEffect,Fragment } from "react";
import axios from "axios";
import { useHistory, Redirect } from "react-router-dom";
import Cookies from 'js-cookie'
import { calculateTimeLeft } from "../views/Auth/forget-password-link/timer.js";
export const AuthContext = createContext();

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData] = useState({});
  const history = useHistory();
  const [endTime, setEndtime] = useState();

  const [timeLeft, setTimeLeft] = useState();

  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {

        setTimeLeft(calculateTimeLeft(endTime));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });
  const setSession = (accessToken, expiryTime,userId) => {
    if (accessToken) {
      console.log("chala",expiryTime);
      Cookies.set('auth', accessToken, { expires: expiryTime })
      Cookies.set('userId',userId,{ expires: expiryTime })
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      Cookies.remove('auth')
      delete axios.defaults.headers.common.Authorization;
    }
    setTimeout(() => {
      Cookies.remove('auth')
      const accessToken = Cookies.get('auth');
      if (!accessToken){
        return <Redirect to="/" />;
      }
      
    }, 3600000);
  };



  function checkLogin(type) {
    console.log("chalja");
    const accessToken = Cookies.get('auth');

    return accessToken ? true : false;
  }
  console.log(isLogin, "isLogin");
  let data = {
    userLoggedIn: isLogin,
    userData,
    setEndtime,
    setTimeLeft,
    timeLeft,
    endTime,
    // setSession,
    userLogIn: (data, type,expiryTime,userId) => {
      setSession(data, expiryTime,userId);
      setIsLogin(type);
    },
  };


  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
