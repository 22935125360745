import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout/index";
import DashboardLayout from "src/layouts/DashboardLayout1";
import LoginLayout1 from "src/layouts/LoginLayout1";
import ForgetasswordLink from "src/views/Auth/forget-password-link";
import Login from "src/views/Auth/forget-password-link/OtpVerify";
import signup from "src/views/Auth/signup/signup.js";
import Index from "src/views/pages/Home/Privacypolicy.js";
import Reset from "src/views/Auth/reset-password/index.js";
import ProfileComp from "src/views/pages/ProfileComp/ProfileSave";
import SignupOtp from "src/views/Auth/signup/SignupOtp.js";
import About from "src/views/pages/Home/about.js";
export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    path: "/dashboard",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Dashboard/dash")),
  },
  {
    exact: true,
    path: "/exchange",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Exchange/index")),
  },
  {
    exact: true,
    path: "/exchang",
    layout: HomeLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Home/Exchange")),
  },

  {
    exact: true,
    path: "/view-profile",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/ProfileComp/Profile")),
  },
  {
    exact: true,
    path: "/editprofile",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/ProfileComp/ProfileSave")),
  },
  {
    exact: true,
    path: "/reset",
    layout: Reset,
    component: lazy(() => import("src/views/Auth/reset-password/index.js")),
  },
  {
    exact: true,
    path: "/change-password",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/ProfileComp/SetPassword")),
  },
  {
    exact: true,
    path: "/bot-setting",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/Wallet/MyWallet")),
  },

  {
    exact: true,
    path: "/transactions",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Dashboard/transactions")),
  },

  {
    exact: true,
    path: "/login",
    layout: LoginLayout,

    component: lazy(() => import("src/views/Auth/LogIn")),
  },
  {
    exact: true,
    path: "/register",
    layout: signup,

    component: lazy(() => import("src/views/Auth/signup/signup.js")),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: SignupOtp,
    component: lazy(() => import("src/views/Auth/signup/SignupOtp.js")),
  },
  {
    exact: true,
    path: "/forgot",
    layout: LoginLayout,
    component: lazy(() => import("src/views/Auth/forget-password/index")),
  },
  {
    exact: true,
    path: "/emailsent",
    layout: ForgetasswordLink,
    component: lazy(() => import("src/views/Auth/forget-password-link/index")),
  },
  {
    exact: true,
    path: "/verifyotp",
    layout: Login,
    component: lazy(() =>
      import("src/views/Auth/forget-password-link/OtpVerify")
    ),
  },

  {
    exact: true,
    path: "/term",
    layout: LoginLayout1,
    component: lazy(() =>
      import("src/views/pages/Home/Terms-and-condition/Index")
    ),
  },
  {
    exact: true,
    path: "/subscriptions",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/subscription")
    ),
  },

  {
    exact: true,
    path: "/policy",
    layout: Index,
    component: lazy(() => import("src/views/pages/Home/Privacypolicy.js")),
  },
  {
    exact: true,
    path: "/About-us",
    layout: About,
    component: lazy(() => import("src/views/pages/Home/Privacypolicy.js")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
