import React from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
// import Footer from "./Footer1";
import TopBar from "./TopBar";
// import Footer from "./Footer"

const useStyles = makeStyles((theme) => ({
  root: {
    background:"#414141",
    // backgroundColor: `linear-gradient(174.64deg, #414141 100%, #004D78 100%)`,
    width:"100%",
    backgroundSize:"cover",
   backgroundImage: "url(images/backgroundimg.svg)",

    "@media (min-width: 1326px)": {
      "& .MuiContainer-root":{
        paddingLeft:"0",
        paddingRight:"0"
      }
    },
  },
  MainLayout:{
   // backgroundSize:"cover",
   // backgroundImage: "url(images/backgroundtony2x.png)",
    minHeight:"calc(100vh - 415px)"
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  console.log(history.location);
  return (
    
    <div className={classes.root}  >
     <TopBar />
      <div
        style={
          history.location?.pathname !== "/"
            ? { display: "block" }
            : { display: "none" }
        }
      ></div>

      <div className={classes.MainLayout}>{children}</div>
      {/* <Footer /> */}
      {/* <Footer/> */}
    </div>
  );
};

export default MainLayout;
