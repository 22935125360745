import React, { useMemo } from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  InputAdornment,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useState, useEffect } from "react";
import axios from "axios";
import FileBase64 from "react-file-base64";
import { useHistory } from "react-router-dom";
import moment from "moment";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import CustomToast from "../../../component/customToast";
import Cookies from "js-cookie";
import Select from "react-select";
import countryList from "react-select-country-list";
import Page from "../../../component/Page";
import countries from "../../../config/countries.json";
import { BASE_URL } from "../../../config/APICongig";
import { values } from "lodash";
const useStyles = makeStyles((theme) => ({
  mainbox: {
    marginTop: "20px",
    paddingBottom: "100px",
    fontFamily: "inter",

    "& h4": {
      fontSize: "30px",
      color: "#000",
      fontfamily: "inter",
      fontWaight: "700",
    },
    "& h3": {
      color: "#000",
      padding: "15px 0px",
      fontSize: "16px",
    },
  },
  imageBase: {
    width: "89px",
    height: "89px",
    border: "1px solid black",
    borderRadius: "100%",
    marginTop: "45px",
  },
  btnsave: {
    width: "100%",
    height: "50px",
    background: "#F2542D",
    borderRadius: "8px",
    fontfamily: "Inter",
    fontStyle: "normal",
    fontWeight: " 700",
    fontSize: "16px",
    lineHeight: " 19px",
    color: "#FFFFFF",
    border: " 3px solid #F2542D",
    "&:hover": {
      background: "#fff",
      color: "#F2542D",
      border: " 3px solid #F2542D",
    },
  },
  box: {
    width: "100%",
    height: "auto",
    border: "1px solid #D9D9D9",
    background: "#fff",
  },
  modalLoader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    left: "0",
    right: "0",
    top: "0",
    bottom: "0",
  },
  loaderDiv: {
    backgroundColor: "transparent",
    border: "none",
    padding: theme.spacing(2, 4, 3),
  },
  MainconatinerBox: {
    paddingLeft: "24px",

    [theme.breakpoints.down("md")]: {
      paddingLeft: "0px",
    },
  },
  textfiled: {
    width: "100%",
    height: "41.16px",
    background: "#FFFFFF",
    border: "1px solid rgba(0, 0, 0, 0.5)",
    boxShadow:
      "0px 100px 80px rgba(41, 72, 152, 0.05), 0px 64.8148px 46.8519px rgba(41, 72, 152, 0.037963), 0px 38.5185px 25.4815px rgba(41, 72, 152, 0.0303704), 0px 20px 13px rgba(41, 72, 152, 0.025), 0px 8.14815px 6.51852px rgba(41, 72, 152, 0.0196296), 0px 1.85185px 3.14815px rgba(41, 72, 152, 0.012037)",
    borderRadius: "10px",
  },
  error: {
    color: "red",
    fontfamily: "Inter",
    fontStyle: "normal",
    fontSize: "12px",
  },
  title2: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#000000",
  },
  editIcon: {
    position: "relative",
    left: "-23px",
    top: "111px",
    right: "0",
    bottom: "0",
  },
  imageLoader: {
    margin: "29px 0 0 19px",
  },
  div: {
    display: "flex",
    flexDirection: "row",
    alignItem: "right",
    padding: "15px 18px 11px 759px",
    gap: "1rem",
    "@media (max-width: 1279px)": {
      padding: "22px 10px 10px 750px",
    },
  },
}));

export default function ProfileSave() {
  const classes = useStyles();
  const history = useHistory();
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState(false);
  const [email, setEmail] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [dateOfBirthError, setDateOfBirthError] = useState(false);
  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState(false);
  const [mobile, setMobile] = useState("");
  const [mobileError, setMobileError] = useState(false);
  const [file, setFile] = useState("");
  const [apiFile, setApiFile] = useState("");
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openLoader, setOpenLoader] = React.useState(false);
  const [value, setValue] = useState({ countryName: { value: "", label: "" } });
  const options = useMemo(() => countryList().getData(), []);
  const [url, setUrl] = useState("");
  const [profileLoader, setProfileLoader] = useState(false);
  const [mobileCode, setMobileCode] = useState("")
  const getCountry = value.countryName.label;
  useEffect(() => {
    console.log(getCountry, "ddfgshgfahf");
    const res = countries.find((element) => element.name == getCountry);
    setMobileCode(res?.dial_code)
    console.log(res, "search");
  }, [getCountry]);

  const changeHandler = (value) => {
    setValue({ countryName: value });
  };
  const handleClose = (x) => {
    setOpen(x);
  };

  const handleOpenLoader = () => {
    setOpenLoader(true);
  };

  const handleCloseLoader = () => {
    setOpenLoader(false);
  };
  const getData = async () => {
    handleOpenLoader();
    try {
      const token = Cookies.get("auth");
      const res = await axios({
        method: "GET",
        url: `${BASE_URL}/getProfile?token=${token}`,
      });
      console.log(res, "resss");
      if (res.data.message === "Get Data Successfully") {
        handleCloseLoader();
        const profileData = res?.data?.user;
        setFirstName(profileData.firstname);
        setLastName(profileData.lastname);
        setEmail(profileData.email);
        setDateOfBirth(profileData.dateOfBrith);
        setValue({
          countryName: { value: "true", label: profileData.country },
        });
        // splitting monile number
        const result = profileData.mobileNumber.split("-");
        // 👉️️ ['+91','8218870363']
        const [first, second] = result;
        console.log(second, "second");
        setMobile(second);
        setApiFile(profileData.userProfilePic);
      }

      return res.data.result;
    } catch (error) {
      handleCloseLoader();
      console.log("Error", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  // edit or save userdata api
  const SaveProfile = async () => {
    setIsLoading(true);
    var res;
    try {
      const token = Cookies.get("auth");
      res = await axios(`${BASE_URL}/editProfile?token=${token}`, {
        method: "PUT",
        data: {
          firstName: firstName,
          lastName: lastName,
          dateOfBrith: dateOfBirth,
          country: value?.countryName?.label,
          mobileNumber: mobileCode+"-"+mobile,
          img: url ? url : apiFile,
        },
      });
      if (res.data.message === "User Profile Update") {
        setIsLoading(false);
        setType("success");
        setOpen(true);
        setMessage("Changes saved successfully.");
        setTimeout(() => {
          history.push("/view-profile");
        }, 1000);
      } else {
        setType("error");
        setOpen(true);
        setMessage(res.response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      setType("error");
      setOpen(true);
      setMessage(error.response.data.message);
    }
  };
  // image uploading function : image is uploaded on cloudinary and then url is generated
  const uploadImage = (image) => {
    setProfileLoader(true);
    const data = new FormData();
    data.append("file", image[0]);
    data.append("upload_preset", "profile");
    fetch("https://api.cloudinary.com/v1_1/dtywzifrd/image/upload", {
      method: "post",
      body: data,
    })
      .then((resp) => resp.json())
      .then((data) => {
        setUrl(data.url);
        setProfileLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setProfileLoader(false);
      });
  };
  console.log(url, "url");
  const [name, setName] = useState([]);
  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    console.log(name, "target");

    if (e.target.name === "firstname") {
      if (target.value === "" || target.value === null) {
        setFirstNameError(true);
        setFirstName("");
      } else {
        setFirstName(target.value);
        setFirstNameError(false);
      }
    } else if (e.target.name === "lastname") {
      if (e.target.value === "" || e.target.value === null) {
        setLastNameError(true);
        setLastName("");
      } else {
        setLastName(target.value);
        setLastNameError(false);
      }
    } else if (e.target.name === "dateofbirth") {
      if (e.target.value === "" || e.target.value === null) {
        setDateOfBirthError(false);
        setDateOfBirth("");
      } else {
        setDateOfBirth(target.value);
        setDateOfBirthError(false);
      }
    } else if (e.target.name === "country") {
      if (e.target.value === "" || e.target.value === null) {
        setCountryError(true);
        setCountry("");
      } else {
        setCountry(target.value);
        setCountryError(false);
      }
    } else if (e.target.name === "mobile") {
      if (e.target.value === "" || e.target.value === null) {
        setMobileError(true);
        setMobile("");
      } else {
        setMobile(target.value);
        setMobileError(false);
      }
    }
  };
  const profileImage =
    apiFile === " " || undefined ? "images/defaultavatar.jpeg" : apiFile;
  return (
    <>
      <Page title="Edit-profile">
        <CustomToast
          type={type}
          handleClose={handleClose}
          open={open}
          message={message}
        />
        <>
          {" "}
          {openLoader === true ? (
            <div className={classes.modalLoader}>
              <div className={classes.loaderDiv}>
                <ButtonCircularProgress />
              </div>
            </div>
          ) : (
            ""
          )}
        </>
        <Box className={classes.MainconatinerBox}>
          <Box className={classes.mainbox}>
            <Container maxWidth="lg">
              <Box pb={4}>
                <Typography variant="h6" style={{ color: "#fff" }}>
                  Edit Profile
                </Typography>
              </Box>
              <Box className={classes.box}>
                <Box pl={4}>
                  <Grid
                    container
                    spacing={4}
                    style={{ padding: "0px 50px 50px 0px" }}
                  >
                    <Grid item xs={12} style={{ display: "flex" }}>
                      <Box
                        style={{
                          backgroundImage: `url(${
                            url
                              ? url
                              : profileImage
                              ? profileImage
                              : "images/defaultavatar.jpeg"
                          })`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                        className={classes.imageBase}
                        id="imageBaseDiv"
                      >
                        {profileLoader == true ? (
                          <div className={classes.imageLoader}>
                            <ButtonCircularProgress />
                          </div>
                        ) : (
                          <></>
                        )}

                        <input
                          type="file"
                          onChange={(e) => uploadImage(e.target.files)}
                        />
                      </Box>
                      <div>
                        <img
                          className={classes.editIcon}
                          src="images/camera.svg"
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={10}
                    style={{ padding: "0px 50px 50px 0px" }}
                  >
                    <Grid item xs={12} sm={6} style={{ paddingTop: "0" }}>
                      <Typography variant="h3" className={classes.title2}>
                        First Name
                      </Typography>
                      <TextField
                        required
                        className={classes.textfiled}
                        id="editField"
                        placeholder="Enter your first name"
                        value={firstName}
                        name="firstname"
                        variant="outlined"
                        onChange={(e) => handleChange(e)}
                        inputProps={{ maxLength: 256 }}
                        fullWidth
                        style={{ color: "#343333" }}
                      />
                      {firstNameError === true ? (
                        <p className={classes.error}>First name is required.</p>
                      ) : (
                        ""
                      )}{" "}
                      <p></p>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ paddingTop: "0" }}>
                      <Typography variant="h3" className={classes.title2}>
                        Last Name
                      </Typography>
                      <TextField
                        className={classes.textfiled}
                        id="editField"
                        placeholder="Enter your last name"
                        value={lastName}
                        name="lastname"
                        onChange={(e) => handleChange(e)}
                        variant="outlined"
                        inputProps={{ maxLength: 256 }}
                        fullWidth
                        style={{ color: "#343333" }}
                      />
                      {lastNameError === true ? (
                        <p className={classes.error}>Last Name is required.</p>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ paddingTop: "0" }}>
                      <Typography variant="h3" className={classes.title2}>
                        Email
                      </Typography>
                      <TextField
                        className={classes.textfiled}
                        disabled
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        variant="outlined"
                        inputProps={{ maxLength: 30 }}
                        fullWidth
                        style={{ color: "#343333" }}
                        pl={2}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ paddingTop: "0" }}>
                      <Typography variant="h3" className={classes.title2}>
                        Date of birth
                      </Typography>
                      <TextField
                        className={classes.textfiled}
                        id="editField outlined-basic"
                        placeholder="Enter your date of birth"
                        value={dateOfBirth}
                        name="dateofbirth"
                        type="date"
                        onChange={(e) => handleChange(e)}
                        variant="outlined"
                        onKeyDown={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                        inputProps={{ max: moment().format("YYYY-MM-DD") }}
                        fullWidth
                        style={{ color: "#343333" }}
                      />
                      {dateOfBirthError === true ? (
                        <p className={classes.error}>
                          Date of birth is required.
                        </p>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ paddingTop: "0" }}>
                      <Typography variant="h3" className={classes.title2}>
                        Country
                      </Typography>

                      <Select
                        className={classes.textfiled}
                        id="countryDropdown"
                        defaultValue={"Select Country"}
                        options={options}
                        value={value?.countryName}
                        onChange={changeHandler}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ paddingTop: "0" }}>
                      <Typography variant="h3" className={classes.title2}>
                        Mobile Number
                      </Typography>
                      <TextField
                        className={classes.textfiled}
                        id="editField"
                        placeholder="Enter your mobile"
                        value={mobile}
                        name="mobile"
                        type="number"
                        onChange={(e) => handleChange(e)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              ( <p>{mobileCode}</p>)
                            </InputAdornment>
                          ),
                        }}
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 16);
                        }}
                        variant="outlined"
                        fullWidth
                        style={{ color: "#343333" }}
                      />
                      {mobileError === true ? (
                        <p className={classes.error}>
                          Mobile Number is required.
                        </p>
                      ) : (
                        ""
                      )}
                    </Grid>{" "}
                  </Grid>
                </Box>
              </Box>
              <Box pt={3}>
                <Grid container spacing={3} style={{ justifyContent: "end" }}>
                  <Grid item xs={2}>
                    <Button
                      onClick={() => history.push("view-profile")}
                      className={classes.btnsave}
                    >
                      CANCEL
                    </Button>
                  </Grid>
                  <Grid item xs={2}>
                    <Button onClick={SaveProfile} className={classes.btnsave}>
                      SAVE {isLoading && <ButtonCircularProgress />}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Box>
        </Box>
      </Page>
    </>
  );
}
