import axios from "axios";
import {BASE_URL} from "../config/APICongig"
// get profile api
export const getProfileData = async (token) => {
    try {
      const res = await axios({
        method: "GET",
        url: `${BASE_URL}/getProfile?token=${token}`,
      });
      return res;
    } catch (error) {
      console.log("Error", error);
    }
  };