export default {
  h1: {
    fontWeight: "800",
    fontSize: "57px",
    fontFamily:'Inter',
    fontStyle:"normal",
    color:"#fff",
    lineHeight: "73px",
    "@media (max-width:767px)": {
      fontSize: "30px",
      lineHeight: "40px",
    },

  },
  h2: {
    fontWeight: "800",
    fontSize: "50px",
    fontFamily:'Inter',
    fontStyle:"normal",
    color:"#fff",
    lineHeight: "60px",
    "@media (max-width:767px)": {
      fontSize: "30px",
      lineHeight: "40px",
    },
  },
  h3: {
    fontWeight: "500",
    fontSize: "20px",
    fontFamily:'Inter',
    fontStyle:"normal",
    color:"#fff",
    lineHeight: "24px",
    "@media (max-width:767px)": {
      fontSize: "20px",
      lineHeight: "24px",
    },
  },
  h4: {
    fontWeight: "300",
    fontSize: "16px",
    fontFamily:'Inter',
    fontStyle:"normal",
    color:"#fff",
    lineHeight: "22px",
    "@media (max-width:767px)": {
      fontSize: "16px",
      lineHeight: "22px",
    },
  },
  h5: {
    fontWeight: "700",
    fontSize: "50px",
    fontFamily:'Inter',
    fontStyle:"normal",
    color:"#fff",
    lineHeight: "61px",
    "@media (max-width:767px)": {
      fontSize: "30px",
      lineHeight: "40px",
    },
  },
  h6: {
    fontWeight: "700",
    fontSize: "30px",
    fontFamily:'Inter',
    fontStyle:"normal",
    color:"#000",
    lineHeight: "36px",
    "@media (max-width:767px)": {
      fontSize: "20px",
      lineHeight: "22px",
    },
  },
  overline: {
    fontWeight: 500,
  },
  button: {
    textTransform: "capitalize",
    borderRadius: 27,
  },
  body1: {
   
    fontSize: "17px",
    fontFamily:'Inter',
    fontStyle:"normal",
    fontWeight: "400px",
    color:"#FFFAFA",

  },
};
