import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import styled from "styled-components"

const DivBack = styled.div`
background: #263944;
// background-image: url(${"./images/TradeBackground.svg"});`;
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#263944",
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 70,
    paddingBottom:"17px !important",
    minHeight: "calc(100vh - 80px)",
    [theme.breakpoints.up("lg")]: {
      // paddingLeft: 250,
      paddingLeft: 235  ,
      paddingRight: " 30px",
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    background: theme.palette.primary.paper,
  },
  content: {
    background: "#263944",
    flex: "1 1 auto",
    height: "100%",
    width: "100%",
    position: "relative",
    WebkitOverflowScrolling: "touch",
    paddingBottom:"84px"
  },
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <DivBack className={classes.wrapper} >
        <div className={classes.contentContainer}>
          <div className={classes.content} id="main-scroll">
            {children}
          </div>
        </div>
      </DivBack>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;